.navbar{
  background-image: url("./images/AjaxWebsiteTopImage.jpg");
  background-repeat: no-repeat;  
  background-size: 100% 100%;
  width: 100%;
  padding-left: 5%;
  padding-right: 5% ;
}

#nav-img{
  margin-bottom: 2% ;
}

a{
  padding-top: 5%;
  font-size: 1.4vw;
  font-family: 'Normal-Regular';
}

button{
  color: white;
  background-color: rgb(64, 151, 257);
  text-decoration: none;
  border: none;
  text-align: center;
}

.navbar h3{
  text-align: center;
  font-size: 2.1vw;
  padding-top: 2%;
  padding-bottom: 15%;
  font-family: 'Normal-ExtraBold';
  
}

.centered-row{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%; 

}

.do-it {
  text-align: center;
}

.nav{
  background-image: none;
  width: 100%;
  position: relative;

}

.news{
  padding-right: 5%;
  padding-left: 5%;
}

.news h3{
  
  text-align: center;
  font-size: 2vw;
  padding-top: 5%;
  padding-bottom: 3%;
  font-family: 'Normal-ExtraBold';
}

.news img{
  float: left;
  padding-right: 2%;
  padding-bottom: 5%;
}

.news p{
  font-size: 1.5vw;
  padding-bottom: 5%;
}

.news a{
  color: rgb(64, 151, 257);
}


.about h3{
  
  text-align: center;
  font-size: 2vw;
  padding-top: 5%;
  padding-bottom: 3%;
  font-family: 'Normal-ExtraBold';
}

.about p{
  font-family: 'Normal-Regular';
  text-align: justify;
  font-size: 2vw;
  padding-right: 5%;
  padding-left: 5%;
  
}

.last-p{
  padding-bottom: 5%;
}

span {
  color: rgb(64, 151, 257);
}

.blueLine{
  border-bottom: 2px solid rgb(64, 151, 257);
  display: block;
  margin-left: 10%;
  margin-right: 10%;
  color: rgb(64, 151, 257);
  margin-top: 5%;
  margin-bottom: 5%;
}

.blackLine{
  border-bottom: .5px black;
  display: block;
  margin-left: 10%;
  margin-right: 10%;
  color: black;
  margin-top: 5%;
  margin-bottom: 5%;
}

.capabilities{
  background-color: rgb(64, 151, 257);
  color: white;
  padding-bottom: 2%;
  padding-bottom: 2%;
}

.capabilities h3{
  padding-top: 5%;
  padding-bottom: 3%;
  text-align: center;
  font-size: 2vw;
  font-family: 'Normal-ExtraBold';
}

.capabilities p{
  font-family: 'Normal-Regular';
  text-align: justify;
  padding-left: 5%;
  font-size: 2vw;
  padding-right: 5%;
  
}

p{
  font-family: 'Normal-Regular';
}

h3{
  font-family: 'Normal-ExtraBold';
}

.capabilities ul{
  padding-left: 8%;
  padding-right: 3%;
  font-size: 1.5vw;
  font-family: 'Normal-Regular';
  padding-bottom: 5%;
  margin-bottom: 0;
  border-bottom: 0;
}

.capabilities-img-container {
  position: relative;
  text-align: center;
  color: white;
  font-size: 2.2vw;
  padding-bottom: 0%;
  margin-bottom: 0%;
  border-bottom: 0%;
  background-size: 100% 100%;
  background-image: url("./images/BlueMountainBottomImg.jpg");
  font-family: 'Normal-ExtraBold';
  
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Normal-ExtraBold';
  font-size: 2.2vw;
}

.leadership{
  padding-left: 5%;
  padding-right: 5%;
  font-size: 1.9vw;
  padding-bottom: 4%;
}

.leadership h3{
  text-align: center;
  font-size: 2vw;
  padding-top: 4%;
  padding-bottom: 3%;
}

.leadership p{
  text-align: justify;
  padding-left: 3%;
  padding-right: 3%;
}

.leadership img{
  float: left;
  padding-right: 2%;
}



.Learn{
  color: rgb(64, 151, 257);
  cursor: pointer;
}

.footer{
  padding-left: 5%;
  font-size: 1.5vw;
  background-color: rgb(64, 151, 257);
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
  font-family: 'Normal-Regular'; 
}

.footer-a{
  font-family: 'Normal-Regular'; 
  font-size: 1.8vw;
}

.footer h3{
  font-size: 2.2vw;
  
}

.footer a:link{
  color: white;
}

.footer a:visited{
  color: white;
}

@font-face {
  font-family: 'Normal-Bold';
  src: url("./fonts/Normal-Bold.otf") format('truetype');
}

@font-face {
  font-family: 'Normal-ExtraBold';
  src: url("./fonts/Normal-ExtraBold.otf") format('truetype');
}

@font-face {
  font-family: 'Normal-ExtraLight';
  src: url("./fonts/Normal-ExtraLight.otf") format('truetype');
}
@font-face {
  font-family: 'Normal-Light';
  src: url("./fonts/Normal-Light.otf") format('truetype');
}
@font-face {
  font-family: 'Normal-Regular';
  src: url("./fonts/Normal-Regular.otf") format('truetype');
}